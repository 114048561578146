import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Button from '../components/Button';

const Wrapper = styled.div`
	padding: 300px 2rem;
	text-align: center;

	h1 {
		font-size: 4rem;
	}
`;

const FourOhFourPage = ({ data }) => {
	return (
		<Layout>
			<Wrapper>
				<h1>404</h1>
				<a href="/">
					<Button size="large">Home Page</Button>
				</a>
			</Wrapper>
		</Layout>
	);
};

export default FourOhFourPage;
